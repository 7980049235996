import { createApp } from 'vue';

import './main.css';
import 'element-plus/es/components/row/style/css';
import 'element-plus/es/components/col/style/css';
import 'element-plus/es/components/link/style/css';
import 'element-plus/es/components/image/style/css';
import 'element-plus/es/components/carousel/style/css';
import 'element-plus/es/components/carousel-item/style/css';
import 'element-plus/es/components/popover/style/css';
import 'element-plus/es/components/dialog/style/css';
import 'element-plus/es/components/button/style/css';
import 'element-plus/es/components/drawer/style/css';
import 'element-plus/es/components/rate/style/css';
import 'element-plus/es/components/card/style/css';
import 'element-plus/es/components/avatar/style/css';
import 'element-plus/es/components/message/style/css';

import {
	ElImage,
	ElRow,
	ElCol,
	ElLink
} from 'element-plus';

import VueGoogleMaps from '@fawmi/vue-google-maps';

import VueGtag from 'vue-gtag';
import router from './router';

import store from './store';
import App from './App.vue';

const app = createApp(App);
app.component('ElImage', ElImage);
app.component('ElRow', ElRow);
app.component('ElCol', ElCol);
app.component('ElLink', ElLink);

app.use(router);
app.use(store);

const loadGoogleAnalitics = async () => {
	await app.use(VueGtag, {
		config: { id: 'G-1TMH8PCW08' }
	}, router);
};

const loadGoogleMapsAPI = async () => {
	await app.use(VueGoogleMaps, {
		load: {
			key: ''
		}
	});
};
loadGoogleAnalitics();
loadGoogleMapsAPI();
app.mount('#app');

export default app;
