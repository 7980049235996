<template>
	<div
		ref="divHeight"
		class="siteWidth">
		<div class="mt-2 mb-2">
			<!-- левая часть -->
			<el-row>
				<el-col :span="8">
					<span
						v-if="getMenuX > 1100"
						class="row-start set-font">
						<fds-menu-singl
							:data="getMenuItems"
						/>
					</span>
					<div
						v-else
						class="mt-4 ms-4">
						<fds-menu-side
							:data="getMenuItems"
						/>
					</div>
				</el-col>
				<!-- центер -->
				<el-col :span="8">
					<div class="tc">
						<el-image
							style="width: 170px; height: 64px; margin-left: auto; margin-right: auto;"
							class="row-center scale"
							src="/image/logo.svg"
							:lazy="true"
							alt="logo"
							@click="this.$router.push({ name: '/' }); allMenuIsBlack();" />
					</div>
				</el-col>
				<!-- правая часть -->
				<el-col :span="8">
					<span class="row-end">
						<fds-icon-list v-if="getMenuX > 420" />
					</span>
				</el-col>
			</el-row>
		</div>
	</div>
	<div class="content-blocker">
		<router-view />
	</div>
	<fds-footer
		v-if="getFooterIsLoaded"
	/>
	<fds-float-contact
		v-if="getFooterIsLoaded"
	/>
</template>

<script>
import {
	mapActions,
	mapGetters,
	mapMutations
} from 'vuex';
import { defineAsyncComponent } from 'vue';
import FdsMenuSingl from './components/FdsMenuSingl.vue';

const FdsMenuSide = defineAsyncComponent(() => import('./components/FdsMenuSide.vue'));
const FdsIconList = defineAsyncComponent(() => import('./components/FdsIconList.vue'));
const FdsFloatContact = defineAsyncComponent(() => import('./components/FdsFloatContact.vue'));
const FdsFooter = defineAsyncComponent(() => import('./components/FdsFooter.vue'));

export default {
	components: {
		FdsMenuSingl,
		FdsMenuSide,
		FdsIconList,
		FdsFloatContact,
		FdsFooter
	},
	data() {
		return {};
	},
	computed: {
		...mapGetters(
			'main',
			[
				'getMenuItems',
				'getMenuX',
				'getFooterIsLoaded'
			]
		)
	},
	methods: {
		...mapActions(
			'main',
			[
				'loadMenuItems',
				'loadReviews',
				'loadTwoPicBlock',
				'loadPicAndText',
				'loadCarousel',
				'loadBigPicWText',
				'loadTwoPicAndTextCenter',
				'loadFAQ',
				'loadfdsBlockArt',
				'loadHowToGetPG'
			]
		),
		...mapMutations(
			'main',
			[
				'setMenuScreenSize',
				'allMenuIsBlack',
				'setMainUrl',
				'getStartLanguage',
				'setLanguage'
			]
		),
		resizeHandler() {
			this.setMenuScreenSize({
				sizeY: this.$refs.divHeight.clientHeight,
				sizeX: this.$refs.divHeight.clientWidth
			});
		}
	},
	created() {
		window.addEventListener('resize', this.resizeHandler);
	},
	mounted() {
		this.resizeHandler();
		this.loadMenuItems();
		this.loadReviews();
		this.setMainUrl(document.location.href);
		this.loadTwoPicBlock();
		this.loadPicAndText();
		this.loadCarousel();
		this.loadBigPicWText();
		this.getStartLanguage();
		this.loadTwoPicAndTextCenter();
		this.loadFAQ();
		this.loadfdsBlockArt();
		this.loadHowToGetPG();
		if (localStorage.lang) {
			this.setLanguage(localStorage.lang);
		}
	},
	unmounted() {
		window.removeEventListener('resize', this.resizeHandler);
	}
};

</script>
<style scoped>
.scale {
	transition: all .2s ease-in-out;
}
.scale:hover {
	transform: scale(1.1);
}

.content-blocker {
	width: 100%;
	height: 50%;
	background-color:white;
	z-index: 1;
}
</style>
