import { createStore } from 'vuex';

// eslint-disable-next-line import/no-cycle
import main from './main';

const store = {
	modules: {
		main: main
	},
	strict: process.env.NODE_ENV !== 'production'
};

export default createStore(store);
