import { ElMessage } from 'element-plus';
import { BASEURL } from '../components/constants';
import utils from '../utils/utils';
// eslint-disable-next-line import/no-cycle
import router from '../router';

export default {
	namespaced: true,
	state: {
		ClickRedirect: false,
		HowToGetPG: [],
		footerIsLoaded: false,
		menuItems: [],
		reviews: [],
		isContact: false,
		FAQ: [],
		mainUrl: '',
		menuX: 0,
		menuY: 0,
		loadMainCourseInfRu: [],
		loadMainCourseInfEn: [],
		TwoPicBlock: [],
		TwoPicAndTextCenter: [],
		PicAndText: [],
		Carousel: [],
		BigPicWText: [],
		language: '',
		BlockArt: [],
		BlockArtEn: []
	},
	getters: {
		getFooterIsLoaded: state => state.footerIsLoaded,
		getHowToGetPG: state => state.HowToGetPG,
		getBlockArt: state => state.BlockArt,
		getBlockArtEn: state => state.BlockArtEn,
		getBlockArtCW: state => (codeW) => {
			if (codeW === 0 || codeW === '') return false;
			return state.BlockArt.filter(item => item.codeW === codeW)[0];
		},
		getBlockArtCWEn: state => (codeW) => {
			if (codeW === 0 || codeW === '') return false;
			return state.BlockArtEn.filter(item => item.codeW === codeW)[0];
		},
		IsHowToGetPG: state => state.HowToGetPG.length !== 0,
		IsBlockArt: state => state.BlockArt.length !== 0,
		IsBlockArtEn: state => state.BlockArtEn.length !== 0,
		getAllFaq: state => state.FAQ,
		getLanguage: state => state.language,
		IsIsFAQ: state => state.FAQ.length !== 0,
		IsMainCourseInfRu: state => state.loadMainCourseInfRu.length !== 0,
		IsMainCourseInfEn: state => state.loadMainCourseInfEn.length !== 0,
		IsMenuItems: state => state.menuItems.length !== 0,
		IsReviews: state => state.reviews.length !== 0,
		IsTwoPicBlock: state => state.TwoPicBlock.length !== 0,
		IsTwoPicAndTextCenter: state => state.TwoPicAndTextCenter.length !== 0,
		IsPicAndText: state => state.PicAndText.length !== 0,
		IsCarousel: state => state.Carousel.length !== 0,
		IsBigPicWText: state => state.BigPicWText.length !== 0,
		getTwoPicAndTextCenter: state => state.TwoPicAndTextCenter,
		getTwoPicAndTextCenterPart: state => codeW => state.TwoPicAndTextCenter.filter(
			item => item.codePart === codeW
		),
		getReviewById: state => (id) => {
			const fItem = state.reviews.find(el => el.id === id);
			if (fItem === undefined) return false;
			return fItem;
		},
		getChangeReviews: (state) => {
			const fItem = state.reviews.find(el => el.mode !== '');
			if (fItem === undefined) return true;
			return false;
		},
		getCountReviews: state => state.reviews.length,
		getMenuItems: state => state.menuItems,
		get3Revievs: (state) => {
			const selRev = [];
			const returnArr = [];
			for (let i = 0; i < 3; i++) {
				selRev[i] = Math.floor(Math.random() * (state.reviews.length - 1));
			}
			for (let i = 0; i < 3; i++) {
				returnArr.push(state.reviews[selRev[i]]);
			}
			return returnArr;
		},
		getAllRevievs: state => state.reviews,
		getMenuX: state => state.menuX,
		getMenuY: state => state.menuY,
		getSize640: state => (state.menuX < 640),
		getCSSForPicAndTextBlock: (state) => {
			if (state.menuX > 900) return 'blockIT';
			if (state.menuX < 900 && state.menuX > 640) return 'blockITSmoll';
			if (state.menuX < 640) return 'blockITCol';
			return 'blockITCol';
		},
		spanSizeForPic: function (state) {
			if (state.menuX > 1100) return 8;
			if (state.menuX > 820 && state.menuX <= 1100) return 10;
			if (state.menuX > 640 && state.menuX <= 820) return 11;
			if (state.menuX > 450 && state.menuX <= 640) return 16;
			return 16;
		},
		getCSSForPicAndTextBlockSec: state => (state.menuX > 1299 ? 'regularPar' : 'regularParSmoll ms-2'),
		getCSSFor2PicBlock: state => (state.menuX > 640 ? 'd-flex align-items-end container' : 'containerCol'),
		getCSSFor2PicBlockSec: state => (state.menuX > 640 ? 'block siteWidth' : 'blockCol siteWidth'),
		getCSSForImage: state => (state.menuX > 640 ? 'image-fit' : 'image-fitVert'),
		getIsContact: state => state.isContact,
		getFAQ: state => (codeW) => {
			if (codeW === 0 || codeW === '') return state.FAQ;
			return state.FAQ.filter(item => item.codeW === codeW);
		},
		getTwoPicBlock: state => (codeW) => {
			if (codeW === 0 || codeW === '') return false;
			const fItem = state.TwoPicBlock.find(el => el.codeW === codeW);
			if (fItem === undefined) return false;
			return fItem;
		},
		getLoadMainCourseInfEn: state => (id) => {
			if (id === 0) return false;
			const fItem = state.loadMainCourseInfEn.find(el => el.codeW === id);
			if (fItem === undefined) return false;
			return fItem;
		},
		getLoadMainCourseInfRu: state => (id) => {
			if (id === 0) return false;
			const fItem = state.loadMainCourseInfRu.find(el => el.codeW === id);
			if (fItem === undefined) return false;
			return fItem;
		},
		getPicAndText: state => (codeW) => {
			if (codeW === 0 || codeW === '') return false;
			const fItem = state.PicAndText.find(el => el.codeW === codeW);
			if (fItem === undefined) return false;
			return fItem;
		},
		getCarousel: state => (codeW) => {
			if (codeW === 0 || codeW === '') return false;
			const fItem = state.Carousel.find(el => el.page === codeW);
			if (fItem === undefined) return false;
			return fItem;
		},
		getBigPicWText: state => (codeW) => {
			if (codeW === 0 || codeW === '') return false;
			const fItem = state.BigPicWText.find(el => el.codeW === codeW);
			if (fItem === undefined) return false;
			return fItem;
		},
		isOpen: state => (id) => {
			const fItem = state.FAQ.find(el => el.id === id);
			return fItem.open;
		},
		getClickRedirect: state => state.ClickRedirect
	},
	mutations: {
		setClickRedirect(state) {
			state.ClickRedirect = true;
		},
		setFooterIsLoaded(state, val) {
			state.footerIsLoaded = val;
		},
		setBlockArt(state, val) {
			state.BlockArt = val;
		},
		setBlockArtEn(state, val) {
			state.BlockArtEn = val;
		},
		chOpen(state, id) {
			const fItem = state.FAQ.find(el => el.id === id);
			if (fItem !== undefined) fItem.open = !fItem.open;
		},
		getStartLanguage(state) {
			if (String(state.mainUrl).includes('localhost')) state.language = 'ru';
			if (String(state.mainUrl).includes('192.168.1')) state.language = 'ru';
			if (String(state.mainUrl).includes('.com')) state.language = 'en';
			if (String(state.mainUrl).includes('.ru'))state.language = 'ru';
		},
		setLanguage(state, val) {
			localStorage.lang = val;
			state.language = val;
		},
		setHowToGetPG(state, val) {
			state.HowToGetPG = val;
		},
		setBigPicWText(state, val) {
			state.BigPicWText = val;
		},
		setCarousel(state, val) {
			state.Carousel = val;
		},
		setPicAndText(state, val) {
			state.PicAndText = val;
		},
		setTwoPicBlock(state, val) {
			state.TwoPicBlock = val;
		},
		setIsContact(state, val) {
			state.isContact = val;
		},
		setMainUrl(state, val) {
			state.mainUrl = val;
		},
		setFAQ(state, val) {
			state.FAQ = val;
		},
		allMenuIsBlack(state) {
			state.menuItems.forEach((el) => { el.color = 'firmBlack'; });
		},
		setMenuScreenSize(state, val) {
			state.menuX = val.sizeX;
			state.menuY = val.sizeY;
		},
		setMenuItems(state, val) {
			state.menuItems = val;
			state.menuItems.forEach((el) => { el.color = 'firmBlack'; });
		},
		setTwoPicAndTextCenter(state, val) {
			state.TwoPicAndTextCenter = val;
		},
		lastSelectedItem: function (state, item) {
			if (item.route === 'hotel') {
				state.ClickRedirect = true;
				router.push(item.path);
				return;
			}
			state.menuItems.forEach((el) => { el.color = 'firmBlack'; });
			const fItem = state.menuItems.find(el => el.id === item.id);
			if (fItem === undefined) return;
			fItem.color = item.color;
			router.push(item.path);
		},
		setReviews(state, val) {
			state.reviews = val;
		},
		setReviewById(state, val) {
			if (val.mode !== 'edit') return;
			const fItem = state.reviews.find(el => el.id === val.id);
			fItem.reviewDate = utils.strtotimeDMY(val.date);
			fItem.rate = val.rate;
			val.text = val.text.replace(/'/g, '&prime;');
			fItem.text = val.text;
			fItem.fromsite = val.fromsite;
			fItem.name = val.name;
			fItem.mode = 'edit';
			fItem.reviewLink = val.reviewLink;
			fItem.avtLink = val.avtLink;
			fItem.save = false;
		},
		setReviewByIdMode(state, { id, mode }) {
			const fItem = state.reviews.find(el => el.id === id);
			fItem.save = false;
			fItem.mode = mode;
		},
		setReviewByIdNew(state, val) {
			if (val.mode !== 'new') return;
			val.reviewDate = utils.strtotimeDMY(val.date);
			val.save = false;
			state.reviews = Array(val).concat(state.reviews);
		},
		setLoadMainCourseInfRu(state, val) {
			state.loadMainCourseInfRu = val;
		},
		setLoadMainCourseInfEn(state, val) {
			state.loadMainCourseInfEn = val;
		},
		updateReviews(state, ids) {
			Array(ids).forEach((el) => {
				if (el[0].mode === 'delete') {
					const index = state.reviews.findIndex(
						item => el[0].id === item.id
					);
					if (index === -1) return;
					state.reviews = [
						...state.reviews.slice(0, index),
						...state.reviews.slice(index + 1)
					];
				}
				if (el[0].mode === 'new' || el[0].mode === 'edit') {
					const fItem = state.reviews.find(
						item => el[0].id === item.id
					);
					if (fItem === undefined) return;
					fItem.id = el[0].newid;
					fItem.save = true;
					fItem.mode = '';
				}
			});
		}
	},
	actions: {
		sentMessToTel: async function ({ getters }, { form, course }) {
			const response = await fetch(BASEURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					task: 'sentMessToTel',
					name: form.name,
					email: form.email,
					mes: form.mes,
					type: form.contactKind,
					course: course
				})
			})
				.catch(error => ElMessage({ type: error.error, message: error.message, showClose: true }));
			const userData = await response.json();
			if (userData.error !== 'error') {
				ElMessage({
					message: 'Your message has been sent',
					duration: 0,
					grouping: true,
					customClass: 'set-font',
					type: 'success',
					showClose: true,
					center: true
				});
			} else {
				console.log(getters.getSize640);
				ElMessage({ type: userData.error, message: userData.problem, showClose: true });
			}
		},
		loadTwoPicAndTextCenter: async function ({ commit, getters }) {
			if (getters.IsTwoPicAndTextCenter) return;
			if (getters.getClickRedirect) return;
			const response = await fetch(BASEURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ task: 'loadTwoPicAndTextCenter' })
			})
				.catch(error => ElMessage({ type: error.error, message: error.message, showClose: true }));
			const userData = await response.json();
			if (userData.error !== 'error') commit('setTwoPicAndTextCenter', userData.data);
			else {
				ElMessage({ type: userData.error, message: userData.problem, showClose: true });
			}
		},
		loadFAQ: async function ({ commit, getters }) {
			if (getters.IsFAQ) return;
			if (getters.getClickRedirect) return;
			const response = await fetch(BASEURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ task: 'loadFAQ' })
			})
				.catch(error => ElMessage({ type: error.error, message: error.message, showClose: true }));
			const userData = await response.json();
			if (userData.error !== 'error') commit('setFAQ', userData.data);
			else {
				ElMessage({ type: userData.error, message: userData.problem, showClose: true });
			}
		},
		loadMainCourseInf: async function ({ commit, getters }) {
			if (getters.IsMainCourseInf) return;
			if (getters.getClickRedirect) return;
			const response = await fetch(BASEURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ task: 'loadMainCourseInf' })
			})
				.catch(error => ElMessage({ type: error.error, message: error.message, showClose: true }));
			const userData = await response.json();
			if (userData.error !== 'error') {
				commit('setLoadMainCourseInfEn', userData.dataEn);
				commit('setLoadMainCourseInfRu', userData.dataRu);
			} else {
				ElMessage({ type: userData.error, message: userData.problem, showClose: true });
			}
		},
		loadHowToGetPG: async function ({ commit, getters }) {
			if (getters.IsHowToGetPG) return;
			if (getters.getClickRedirect) return;
			const response = await fetch(BASEURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ task: 'loadHowToGetPG' })
			})
				.catch(error => ElMessage({ type: error.error, message: error.message, showClose: true }));
			const userData = await response.json();
			if (userData.error !== 'error') commit('setHowToGetPG', userData.data);
			else {
				ElMessage({ type: userData.error, message: userData.problem, showClose: true });
			}
		},
		loadMenuItems: async function ({ commit, getters }) {
			if (getters.IsMenuItems) return;
			if (getters.getClickRedirect) return;
			const response = await fetch(BASEURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ task: 'loadMenuItem' })
			})
				.catch(error => ElMessage({ type: error.error, message: error.message, showClose: true }));
			const userData = await response.json();
			if (userData.error !== 'error') commit('setMenuItems', userData.menu);
			else {
				ElMessage({ type: userData.error, message: userData.problem, showClose: true });
			}
		},
		loadReviews: async function ({ commit, getters }) {
			if (getters.IsReviews) return;
			if (getters.getClickRedirect) return;
			const response = await fetch(BASEURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ task: 'loadReviews' })
			})
				.catch(error => ElMessage({ type: error.error, message: error.message, showClose: true }));
			const userData = await response.json();
			if (userData.error !== 'error') commit('setReviews', userData.reviews);
			else {
				ElMessage({ type: userData.error, message: userData.problem, showClose: true });
			}
		},
		loadTwoPicBlock: async function ({ commit, getters }) {
			if (getters.IsTwoPicBlock) return;
			if (getters.getClickRedirect) return;
			const response = await fetch(BASEURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ task: 'loadTwoPicBlock' })
			})
				.catch(error => ElMessage({ type: error.error, message: error.message, showClose: true }));
			const userData = await response.json();
			if (userData.error !== 'error') commit('setTwoPicBlock', userData.data);
			else {
				ElMessage({ type: userData.error, message: userData.problem, showClose: true });
			}
		},
		loadPicAndText: async function ({ commit, getters }) {
			if (getters.IsPicAndText) return;
			if (getters.getClickRedirect) return;
			const response = await fetch(BASEURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ task: 'loadfdsPicAndText' })
			})
				.catch(error => ElMessage({ type: error.error, message: error.message, showClose: true }));
			const userData = await response.json();
			if (userData.error !== 'error') commit('setPicAndText', userData.data);
			else {
				ElMessage({ type: userData.error, message: userData.problem, showClose: true });
			}
		},
		loadfdsBlockArt: async function ({ commit, getters }) {
			if (getters.IsBlockArt) return;
			if (getters.getClickRedirect) return;
			const response = await fetch(BASEURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ task: 'loadfdsBlockArt' })
			})
				.catch(error => ElMessage({ type: error.error, message: error.message, showClose: true }));
			const userData = await response.json();
			if (userData.error !== 'error') {
				commit('setBlockArt', userData.data);
				commit('setBlockArtEn', userData.dataEn);
			} else {
				ElMessage({ type: userData.error, message: userData.problem, showClose: true });
			}
		},
		loadCarousel: async function ({ commit, getters }) {
			if (getters.IsCarousel) return;
			if (getters.getClickRedirect) return;
			const response = await fetch(BASEURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ task: 'loadCarousel' })
			})
				.catch(error => ElMessage({ type: error.error, message: error.message, showClose: true }));
			const userData = await response.json();
			if (userData.error !== 'error') commit('setCarousel', userData.data);
			else {
				ElMessage({ type: userData.error, message: userData.problem, showClose: true });
			}
		},
		loadBigPicWText: async function ({ commit, getters }) {
			if (getters.IsBigPicWText) return;
			if (getters.getClickRedirect) return;
			const response = await fetch(BASEURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ task: 'loadBigPicWText' })
			})
				.catch(error => ElMessage({ type: error.error, message: error.message, showClose: true }));
			const userData = await response.json();
			if (userData.error !== 'error') commit('setBigPicWText', userData.data);
			else {
				ElMessage({ type: userData.error, message: userData.problem, showClose: true });
			}
		}
	}
};
