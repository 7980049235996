/* eslint-disable import/prefer-default-export */
// export const BASEURL =
// `${window.location.origin.split(':')[0]}:${window.location.origin.split(':')[1]}:9999/api/`;
// export const BASEURL = '/api/';

function getPublicPath() {
	switch (process.env.NODE_ENV) {
	case 'production': return '/api/';
	case 'development': return 'http://localhost:9999/api/';
	default: return '1';
	}
}

module.exports = {
	BASEURL: getPublicPath()
};
