<template>
	<ul	class="mt-4">
		<li
			v-for="item in data"
			:key="item.id"
		>
			<a
				v-if="getLanguage === 'en'"
				class="local-link"
				:class="item.color"
				aria-current="page"
				:href="item.path"
				@click.prevent="lastSelectedItem({
					id: item.id,
					color: 'firmRed',
					route: item.route,
					lan: 'en',
					name: item.name,
					path: item.path,
				});">
				{{ item.name }}
			</a>
			<a
				v-if="getLanguage === 'ru'"
				class="local-link"
				:class="item.color"
				aria-current="page"
				:href="item.path"
				@click.prevent="lastSelectedItem({
					id: item.id,
					color: 'firmRed',
					route: item.route,
					lan: 'ru',
					name: item.nameRu,
					path: item.path,
				});">
				{{ item.nameRu }}
			</a>
		</li>
	</ul>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
	props: {
		data: { type: Array, required: true }
	},
	computed: {
		...mapGetters('main', ['getLanguage'])
	},
	methods: {
		...mapMutations('main', ['lastSelectedItem'])
	}
};
</script>

<style scoped>

ul li:not(:first-child) {
	padding-left: 25px;
}
ul li {
	font-weight: 600;
	padding-left: 0px;
	display: inline-block;
}
a {
	text-decoration: none; /* Отменяем подчеркивание у ссылки */
}
a:link {
	color: #505456;
}
/* mouse over link */
a:hover {
	color: #b1b1b1;
}

/* selected link */
a:active {
	color: #EC5F65;
}
</style>
