import { createRouter, createWebHistory } from 'vue-router';

import { seoGuardWithNext } from '@aminoeditor/vue-router-seo';

// import { useStore } from 'vuex';
// eslint-disable-next-line import/no-cycle
import store from '../store/index';

const routes = [
	{
		name: '/',
		path: '/',
		alias: '/home',
		component: () => import('../components/PpHomePage.vue'),
		meta: {
			seo: {
				title: '',
				titleRu: 'Freediving Inn - изучайте, совершенствуйтесь и наслаждайтесь фридайвингом в Пуэрто Галера',
				titleEn: 'Freediving Inn - Learn, Improve, and Enjoy Freediving in Puerto Galera',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Dive into the world of freediving at Freediving Inn, the first freediving school in Puerto Galera, Philippines. Located right on the beach, our school offers internationally certified instructors, perfect conditions for freediving year-round, and stunning underwater landscapes. Join us for basic courses, freediving packages, and unforgettable experiences in the crystal-clear waters of Puerto Galera. Book your course now and explore the depths of the ocean with Freediving Inn.',
						contentRu: 'Окунитесь в мир фридайвинга в Freediving Inn, первой школе фридайвинга в Пуэрто-Галера, Филиппины. Расположенная прямо на пляже, наша школа предлагает международно сертифицированных инструкторов, идеальные условия для фридайвинга круглый год и потрясающие подводные пейзажи. Присоединяйтесь к нам на базовых курсах, фридайвинг-пакетах и незабываемых впечатлениях в бирюзовых водах Пуэрто-Галера. Забронируйте свой курс сейчас и исследуйте глубины океана с Freediving Inn.'
					},
					{
						name: 'viewport',
						content: 'width=device-width, initial-scale=1.0,minimum-scale=1.0'
					}
				],
				richSnippet: {
					'@context': 'https://freediveschool.com',
					'@type': 'freedive school',
					name: 'freediving in the Philippines',
					url: 'https://freediveschool.com',
					logo: 'https://freediveschool.com/image/logo.svg',
					sameAs: [
						'https://t.me/mila_freediveinn',
						'https://api.whatsapp.com/send/?phone=639297717228&text&type=phone_number&app_absent=0',
						'https://m.me/freediveinn',
						'https://www.instagram.com/freedive_inn/',
						'https://web.facebook.com/freediveinn'
					]
				}
			}
		}
	}, {
		name: 'freediving',
		path: '/freediving',
		component: () => import('../components/PpFreedivingPage.vue'),
		meta: {
			seo: {
				title: '',
				titleRu: 'Отправляйтесь в свое путешествие по фридайвингу с увлекательными курсами в Пуэрто-Галера',
				titleEn: 'Embark on Your Freediving Journey with Exciting Courses in Puerto Galera',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentRu: 'Погрузитесь в мир фридайвинга с нашими курсами в Пуэрто-Галера! Исследуйте серию Molchanovs Wave, начиная с уровня начинающего и заканчивая мастерским, и улучшите свои навыки в задержке дыхания, погружении на глубину и технике. Присоединяйтесь к нашим тренировочным сессиям для индивидуального руководства и практики с линией для улучшения вашего выступления. Выберите из пакетов, таких как "От нуля до героя" для всестороннего обучения или "Быстрый старт" с проживанием включено. Начните свое приключение по фридайвингу в Пуэрто-Галера уже сегодня!',
						contentEn: 'Dive into the world of freediving with our courses in Puerto Galera! Explore the Molchanovs Wave series, from beginner to master levels, and enhance your skills in breath-holding, depth diving, and technique refinement. Join our coaching sessions for personalized guidance and line practice to improve your performance. Choose from packages like "From Zero to Hero" for a comprehensive training experience or "The Fast Start" with accommodation included. Start your freediving adventure in Puerto Galera today!'
					}
				]
			}
		}
	}, {
		name: 'hotel',
		path: '/hotel',
		beforeEnter: () => {
			window.location.href = 'https://hotels.cloudbeds.com/reservation/w17U4E';
		},
		meta: {
			seo: {
				title: '',
				titleRu: 'FreeDive Inn проживание на первой линии моря',
				titleEn: 'FreeDive Inn - accommodation on the first line of the sea',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentRu: 'FreeDive Inn - ваш уютный дом на берегу моря. Расположенный на первой линии пляжа, наш отель предлагает комфортабельные номера с видом на бескрайние воды океана. Пробудившись под шум прибоя, вы сможете насладиться завтраком на террасе с видом на бесконечное голубое пространство. Погрузитесь в атмосферу расслабления и отдыха в FreeDive Inn - вашем уединенном уголке рая на побережье.',
						contentEn: 'FreeDive Inn - your cozy home by the sea. Located on the first line of the beach, our hotel offers comfortable rooms with views of the endless ocean waters. Waking up to the sound of the waves, you can enjoy breakfast on the terrace overlooking the infinite blue expanse. Immerse yourself in the atmosphere of relaxation and rest at FreeDive Inn - your secluded corner of paradise on the coast.'
					}
				]
			}
		}
	}, {
		name: 'wave1',
		path: '/freediving/base-course/wave1',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'wave1' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Dive into the World of Freediving with Molchanovs Wave 1 - Beginner Course in Puerto Galera',
				titleRu: 'Окунитесь в мир фридайвинга с курсом для начинающих Molchanovs Wave 1 в Пуэрто-Галера',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Dive into the world of freediving with the Molchanovs Wave 1 - Beginner course in Puerto Galera. This 3-day course, priced at $250, is designed for safe and enjoyable exploration of the underwater world. Learn basics like breath-holding, diving efficiency, and mental preparation. Gain practical skills in pool and open water sessions. Become an autonomous freediver capable of diving comfortably between 12 and 20 meters. Join us in Puerto Galera for an unforgettable freediving experience!',
						contentRu: 'Окунитесь в мир фридайвинга с курсом для начинающих Molchanovs Wave 1 в Пуэрто-Галера. Этот трехдневный курс стоимостью $250 разработан для безопасного и приятного исследования подводного мира. Изучите основы, такие как задержка дыхания, эффективность погружения и психологическая подготовка. Получите практические навыки на уроках в бассейне и открытой воде. Станьте автономным фридайвером, способным комфортно погружаться на глубину от 12 до 20 метров. Присоединяйтесь к нам в Пуэрто-Галера для незабываемого опыта фридайвинга!'
					}
				]
			}
		}
	}, {
		name: 'wave2',
		path: '/freediving/base-course/wave2',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'wave2' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Molchanovs Wave 2 Freediving Course in Puerto Galera',
				titleRu: 'Курс фридайвинга Molchanovs Wave 2 в Пуэрто-Галера',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Dive deeper with the Molchanovs Wave 2 Freediving Course in Puerto Galera. Priced at $350, this 4-5 day intermediate course will enhance your skills and allow you to dive up to 30 meters deep. Includes equipment, certification, pool and open water sessions, theoretical classes, and specialized exercises. Book now and take your freediving to the next level!',
						contentRu: 'Погрузитесь глубже с курсом фридайвинга Molchanovs Wave 2 в Пуэрто-Галера. Стоимость - $350, продолжительность 4-5 дней. Этот промежуточный курс поможет улучшить ваши навыки и позволит погружаться на глубину до 30 метров. Включает в себя оборудование, сертификацию, занятия в бассейне и открытой воде, теоретические занятия и специализированные упражнения. Забронируйте сейчас и поднимите свое фридайвинг на новый уровень!'
					}
				]
			}
		}
	}, {
		name: 'wave3',
		path: '/freediving/base-course/wave3',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'wave3' },
		meta: {
			seo: {
				title: 'Molchanovs Wave 3 - Master Freediving Course in Puerto Galera',
				metaTags: [
					{
						name: 'description',
						content: 'Enhance your freediving skills with the Molchanovs Wave 3 - Master Freediving Course in Puerto Galera. Priced at $490, this 7-9 day course allows you to dive up to 40 meters deep. Develop advanced physiological and mental skills, learn advanced equalization approaches, and master techniques like packing and reverse packing. Requirements include a Wave 2 certificate or crossover evaluation, active first aid certification, and specific pool and open water skill levels. Book now for an unforgettable experience!'
					}
				]
			}
		}
	}, {
		name: 'InstructorCrossover',
		path: '/freediving/instructor-courses/instructorcrossover',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'instructorcrossover' },
		meta: {
			seo: {
				title: '',
				titleEn: ' Molchanovs Instructor Crossover Course',
				titleRu: 'Курс Molchanovs Инструктор Crossover',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Take your freediving skills to the next level with the Molchanovs Crossover Course. Priced at $100 for a 1-day session, this course is designed for experienced freedivers looking to advance their training. Benefit from expert instruction, access quality training materials, and improve your skills in various aspects of freediving. Connect with like-minded individuals, share experiences, and prepare for more advanced levels of Molchanovs training. Book now and unlock new possibilities in the world of freediving!',
						contentRu: 'Поднимите свои навыки фридайвинга на новый уровень с курсом Molchanovs Crossover. Стоимость - $100 за 1-дневное занятие. Этот курс предназначен для опытных фридайверов, желающих продвинуть свою тренировку. Получите экспертное руководство, доступ к качественным учебным материалам и улучшите свои навыки в различных аспектах фридайвинга. Общайтесь с единомышленниками, делитесь опытом и готовьтесь к более продвинутым уровням обучения Molchanovs. Забронируйте сейчас и откройте новые возможности в мире фридайвинга!'
					}
				]
			}
		}
	}, {
		name: 'InstructorW3I',
		path: '/freediving/instructor-courses/InstructorW3I',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'instructorW3I' },
		meta: {
			seo: {
				title: '',
				titleEn: ' Molchanovs Instructor Crossover Course',
				titleRu: 'Курс Molchanovs Инструктор Crossover',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Take your freediving skills to the next level with the Molchanovs Crossover Course. Priced at $100 for a 1-day session, this course is designed for experienced freedivers looking to advance their training. Benefit from expert instruction, access quality training materials, and improve your skills in various aspects of freediving. Connect with like-minded individuals, share experiences, and prepare for more advanced levels of Molchanovs training. Book now and unlock new possibilities in the world of freediving!',
						contentRu: 'Поднимите свои навыки фридайвинга на новый уровень с курсом Molchanovs Crossover. Стоимость - $100 за 1-дневное занятие. Этот курс предназначен для опытных фридайверов, желающих продвинуть свою тренировку. Получите экспертное руководство, доступ к качественным учебным материалам и улучшите свои навыки в различных аспектах фридайвинга. Общайтесь с единомышленниками, делитесь опытом и готовьтесь к более продвинутым уровням обучения Molchanovs. Забронируйте сейчас и откройте новые возможности в мире фридайвинга!'
					}
				]
			}
		}
	}, {
		name: 'InstructorW2I',
		path: '/freediving/instructor-courses/InstructorW2I',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'instructorW2I' },
		meta: {
			seo: {
				title: '',
				titleEn: ' Molchanovs Instructor Crossover Course',
				titleRu: 'Курс Molchanovs Инструктор Crossover',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Take your freediving skills to the next level with the Molchanovs Crossover Course. Priced at $100 for a 1-day session, this course is designed for experienced freedivers looking to advance their training. Benefit from expert instruction, access quality training materials, and improve your skills in various aspects of freediving. Connect with like-minded individuals, share experiences, and prepare for more advanced levels of Molchanovs training. Book now and unlock new possibilities in the world of freediving!',
						contentRu: 'Поднимите свои навыки фридайвинга на новый уровень с курсом Molchanovs Crossover. Стоимость - $100 за 1-дневное занятие. Этот курс предназначен для опытных фридайверов, желающих продвинуть свою тренировку. Получите экспертное руководство, доступ к качественным учебным материалам и улучшите свои навыки в различных аспектах фридайвинга. Общайтесь с единомышленниками, делитесь опытом и готовьтесь к более продвинутым уровням обучения Molchanovs. Забронируйте сейчас и откройте новые возможности в мире фридайвинга!'
					}
				]
			}
		}
	}, {
		name: 'crossover',
		path: '/freediving/base-course/crossover',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'crossover' },
		meta: {
			seo: {
				title: '',
				titleEn: ' Molchanovs Crossover Course',
				titleRu: 'Курс Molchanovs Crossover',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Take your freediving skills to the next level with the Molchanovs Crossover Course. Priced at $100 for a 1-day session, this course is designed for experienced freedivers looking to advance their training. Benefit from expert instruction, access quality training materials, and improve your skills in various aspects of freediving. Connect with like-minded individuals, share experiences, and prepare for more advanced levels of Molchanovs training. Book now and unlock new possibilities in the world of freediving!',
						contentRu: 'Поднимите свои навыки фридайвинга на новый уровень с курсом Molchanovs Crossover. Стоимость - $100 за 1-дневное занятие. Этот курс предназначен для опытных фридайверов, желающих продвинуть свою тренировку. Получите экспертное руководство, доступ к качественным учебным материалам и улучшите свои навыки в различных аспектах фридайвинга. Общайтесь с единомышленниками, делитесь опытом и готовьтесь к более продвинутым уровням обучения Molchanovs. Забронируйте сейчас и откройте новые возможности в мире фридайвинга!'
					}
				]
			}
		}
	}, {
		name: 'packages-offer',
		path: '/freediving/packages-offer',
		component: () => import('../components/PpPackagePage.vue'),
		props: { search: 'packages' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Ultimate Freediving Experience in Puerto Galera',
				titleRu: 'Уникальный опыт фридайвинга в Пуэрто-Галера',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentRu: 'Погрузитесь в мир фридайвинга с нашими эксклюзивными пакетами, разработанными для всех уровней опыта. От обширной программы "От нуля до героя" до интенсивного курса "Мастер фридайвинга", наши предложения предоставляют глубокое погружение в безопасность, техники и исследования. С вариантами, включающими проживание, экскурсии и профессиональное обучение, вы можете отправиться в трансформационное путешествие в Пуэрто-Галера. Независимо от того, являетесь ли вы начинающим, стремящимся к сертификации, или опытным фридайвером, желающим улучшить свои навыки, наши пакеты предлагают уникальную возможность поднять ваше подводное приключение на новый уровень. Присоединяйтесь к нам для незабываемого опыта и откройте глубины океана с нашими специализированными обучающими программами.',
						contentEn: 'Immerse yourself in the world of freediving with our exclusive packages tailored for all levels of experience. From the comprehensive "From Zero to Hero" program to the intense "Master Freediving Course," our offerings provide a deep dive into safety, techniques, and exploration. With options including accommodation, excursions, and expert training, you can embark on a transformative journey in Puerto Galera. Whether you\'re a beginner seeking certification or an experienced freediver looking to enhance your skills, our packages offer a unique opportunity to elevate your underwater adventures. Join us for an unforgettable experience and unlock the depths of the ocean with our specialized training programs.'
					}
				]
			}
		}
	}, {
		name: 'special-courses',
		path: '/freediving/special-courses',
		component: () => import('../components/PpSpecCoursePage.vue'),
		props: { search: 'speccourse' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Specialized Freediving Courses for Experienced Divers',
				titleRu: 'Специализированные курсы фридайвинга для опытных дайверов',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Elevate your freediving skills with our specialized courses designed for experienced divers. Explore new techniques, practice with expert instructors, and push your limits in the water. From personalized coaching to unique courses like the MouthFill and Breath Holding Course for Kayakers, we offer a tailored experience to enhance your underwater adventures. Join us to take your freediving journey to the next level.',
						contentRu: 'Поднимите свои навыки фридайвинга с нашими специализированными курсами, разработанными для опытных дайверов. Исследуйте новые техники, тренируйтесь с опытными инструкторами и превышайте свои пределы в воде. От индивидуального коучинга до уникальных курсов, таких как курс по наполнению рта и задержке дыхания для каякеров, мы предлагаем персонализированный опыт для улучшения ваших подводных приключений. Присоединяйтесь к нам, чтобы поднять ваше путешествие по фридайвингу на новый уровень.'
					}
				]
			}
		}
	}, {
		name: 'instructor-courses',
		path: '/freediving/instructor-courses',
		component: () => import('../components/PpSpecCoursePage.vue'),
		props: { search: 'instructorCourses' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Elevate Your Freediving Skills with Molchanovs Instructor Courses',
				titleRu: 'Откройте свой потенциал как инструктора по фридайвингу с Molchanovs!',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Unlock your potential as a freediving instructor with Molchanovs! Whether you\'re looking to become a Molchanovs Wave 2 or Wave 3 Instructor, or you\'re ready to crossover from another certification, our courses are designed to enhance your teaching abilities and deepen your knowledge.- Molchanovs Wave 2 Instructor Course (w3i): Take your first step towards becoming a certified instructor. Learn essential teaching techniques and prepare to guide students through their freediving journey- Molchanovs Wave 3 Instructor Course: Perfect for experienced instructors, this course will refine your skills and enable you to train and certify advanced freedivers at the Lap 3/Wave 3 level.- Molchanovs Instructor Crossover: Already certified with another organization? Transition seamlessly to the Molchanovs method and start teaching with confidence!Join the ranks of leading freediving educators and elevate your teaching skills with Molchanovs today!',
						contentRu: 'Независимо от того, хотите ли вы стать инструктором Molchanovs Wave 2 или Wave 3, или готовы перейти с другой сертификации, наши курсы предназначены для повышения ваших педагогических навыков и углубления ваших знаний.- Курс инструктора Molchanovs Wave 2 (w3i): Сделайте первый шаг к тому, чтобы стать сертифицированным инструктором. Изучите основные методы преподавания и подготовьтесь к тому, чтобы вести студентов в их путешествии по фридайвингу. - Курс инструктора Molchanovs Wave 3: Идеально подходит для опытных инструкторов, этот курс поможет вам отточить свои навыки и даст возможность обучать и сертифицировать продвинутых фридайверов на уровне Lap 3/Wave 3. - Кроссовер инструктора Molchanovs: Уже сертифицированы в другой организации? Переходите к методу Molchanovs без проблем и начинайте преподавать с уверенностью! Присоединяйтесь к рядам ведущих педагогов по фридайвингу и повышайте свои преподавательские навыки с Molchanovs уже сегодня!'
					}
				]
			}
		}
	}, {
		name: 'mastercourse',
		path: '/freediving/packages/master-course',
		alias: '/freediving/packages-offer/master-course',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'mastercourse' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Freediving Master Course',
				titleRu: 'Мастер-курс по фридайвингу',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Dive deep into the world of freediving with our intensive Freediving Master Course priced at $900. Over 4 weeks in Puerto Galera, Asia, you\'ll undergo the Level 3 deep diving course, preparing you for safe and deep dives up to 40 meters. The course includes equipment, training materials, certification, personal training plan, unlimited open water sessions, pool sessions, morning warm-up participation, and the Wave 3 course.',
						contentRu: 'Погрузитесь в мир фридайвинга с нашим интенсивным Мастер-курсом по фридайвингу по цене $900. На протяжении 4 недель в Пуэрто-Галера, Азия, вы пройдете курс глубокого погружения уровня 3, подготовившись к безопасным и глубоким погружениям до 40 метров. В курс входят оборудование, учебные материалы, сертификация, индивидуальный план тренировок, неограниченные сессии в открытой воде, сессии в бассейне, утренняя разминка, а также курс Wave 3.'
					},
					{
						name: 'requirements',
						content: 'Lap 2 certificate for Lap 3, Wave 2 certificate for Wave 3, completed crossover evaluation, and active first aid certificate'
					}
				]
			}
		}
	}, {
		name: 'trsession',
		path: '/freediving/special-courses/line-practice',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'trsession' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Personalized Freediving Training Session with Instructor Support',
				titleRu: 'Персонализированная сессия тренировок по фридайвингу с поддержкой инструктора',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Immerse yourself in a customized freediving training session with instructor support for $40. This 2-3 hour session offers the opportunity to practice under the guidance of an experienced instructor or master student. They will set up a buoy for you, accompany you in the water, and ensure your safety throughout the session. While there are no specific coaching exercises, the instructor will act as your buddy and oversee the training.',
						contentRu: 'Окунитесь в индивидуальную сессию тренировок по фридайвингу с поддержкой инструктора за $40. Эта сессия длится 2-3 часа и предоставляет возможность практиковаться под руководством опытного инструктора или мастер-студента. Они установят буй для вас, сопровождают вас в воде и обеспечат вашу безопасность на протяжении всей сессии. Хотя нет конкретных упражнений по тренировке, инструктор будет действовать как ваш партнер и контролировать процесс тренировки.'
					}
				]
			}
		}
	}, {
		name: 'chsession',
		path: '/freediving/special-courses/coaching-sessions',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'chsession' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Personalized Freediving Coaching Session with Certified Instructor',
				titleRu: 'Персональная сессия тренировок по фридайвингу с сертифицированным инструктором',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Dive into a tailored freediving coaching session with a certified instructor for $60. In this 3-4 hour session, you\'ll receive personalized guidance based on your goals and objectives. The instructor will adapt the lesson to your needs, providing immediate feedback and a post-session summary. All equipment is included, and the maximum depth will be determined by your level. Whether you\'re looking to refine specific skills or enhance your overall performance, this coaching session will help you reach new depths in your freediving journey.',
						contentRu: 'Окунитесь в индивидуальную сессию тренировок по фридайвингу с сертифицированным инструктором за $60. В этой сессии продолжительностью 3-4 часа вы получите персонализированное руководство, основанное на ваших целях и задачах. Инструктор адаптирует урок под ваши потребности, обеспечивая мгновенную обратную связь и резюме после сессии. Вся необходимая экипировка включена, а максимальная глубина будет определена вашим уровнем. Будь то желание усовершенствовать конкретные навыки или улучшить общую производительность, эта сессия тренировок поможет вам достичь новых глубин в вашем путешествии по фридайвингу.'
					}
				]
			}
		}
	}, {
		name: 'fromzerotohero',
		path: '/freediving/packages/from-zero-to-hero',
		alias: '/freediving/packages-offer/from-zero-to-hero',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'fromzerotohero' },
		meta: {
			seo: {
				title: '',
				titleEn: 'From Zero to Hero Freediving Program',
				titleRu: 'Программа фридайвинга "От нуля до героя"',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Join our comprehensive From Zero to Hero freediving program for $1500. Over 6 weeks, you\'ll learn the fundamentals of freediving, advanced techniques, safety procedures, and emergency response skills. The program includes equipment rental, study materials, certification, unlimited open water sessions, a personal training program, and dry training.',
						contentRu: 'Присоединяйтесь к нашей всесторонней программе фридайвинга "От нуля до героя" за $1500. За 6 недель вы изучите основы фридайвинга, продвинутые техники, процедуры безопасности и навыки реагирования в чрезвычайных ситуациях. Программа включает аренду оборудования, учебные материалы, сертификацию, неограниченные сессии на открытой воде, персональную тренировочную программу и сухие тренировки.'
					}
				]
			}
		}
	}, {
		name: 'faststart',
		path: '/freediving/packages/fast-start',
		alias: '/freediving/packages-offer/fast-start',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'faststart' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Fast Start Freediving Program: Dive Deeper, Hold Longer',
				titleRu: 'Программа фридайвинга "Быстрый старт": погружайтесь глубже, держитесь дольше',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Dive into the exciting world of freediving with our Fast Start program! Learn essential skills, techniques, and safety protocols to dive up to 30 meters and hold your breath for up to 3 minutes. Enjoy yoga sessions, cross-training, and real-world practice at nearby dive sites. Accommodation included. Start your freediving journey today!',
						contentRu: 'Окунитесь в захватывающий мир фридайвинга с нашей программой "Быстрый старт"! Изучите основные навыки, техники и протоколы безопасности для погружения до 30 метров и задержки дыхания до 3 минут. Наслаждайтесь сеансами йоги, кросс-тренировками и практикой на реальных погружениях на близлежащих дайв-сайтах. Проживание включено. Начните свое путешествие в мир фридайвинга уже сегодня!'
					}
				]
			}
		}
	}, {
		name: 'allpg',
		path: '/freediving/packages/all-puerto-galera',
		alias: '/freediving/packages-offer/all-puerto-galera',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'allpg' },
		meta: {
			seo: {
				title: '',
				titleEn: 'All of Puerto Galera Freediving Packages',
				titleRu: 'Пакет "весь Пуэрто-Галера"',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Immerse yourself in the beauty of Puerto Galera with our 6-day freediving tour package. Dive up to 20 meters deep, discover marine life, and explore local attractions. Enjoy Molchanovs Wave 1 / Lap 1 training, excursions, and accommodation. Dive into a world of adventure and unforgettable experiences in Puerto Galera!',
						contentRu: 'Окунитесь в красоту Пуэрто-Галера с нашим 6-дневным туром по фридайвингу. Погружайтесь на глубину до 20 метров, открывайте морскую жизнь и исследуйте местные достопримечательности. Наслаждайтесь обучением Molchanovs Wave 1 / Lap 1, экскурсиями и проживанием. Погрузитесь в мир приключений и незабываемых впечатлений в Пуэрто-Галера!'
					}
				]
			}
		}
	}, {
		name: 'wave3p',
		path: '/freediving/packages/wave-3-package',
		alias: '/freediving/packages-offer/wave-3-package',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'wave3p' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Wave 3 Freediving Course: Dive Deeper, Reach New Depths',
				titleRu: 'Курс по фридайвингу Wave 3: Погружайтесь глубже, достигайте новых глубин',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentRu: 'Углубите свои навыки фридайвинга с курсом Wave 3. Продолжительность - 12 дней, цена от $1000. Включает в себя тренировки, проживание, сертификацию, обучение React Right и дополнительные занятия. Изучите продвинутые техники для погружений до 30 метров и наслаждайтесь новыми подводными приключениями. Требуется сертификация Lap 2/Wave 2 и обучение первой помощи. Готовы ли вы расширить свои возможности и исследовать подводный мир на новом уровне?',
						contentEn: 'Deepen your freediving skills with the Wave 3 course. Duration - 12 days, price starting from $1000. Includes training, accommodation, certification, React Right training, and additional sessions. Learn advanced techniques for dives up to 30 meters and enjoy new underwater adventures. Requires Lap 2/Wave 2 certification and first aid training. Are you ready to expand your capabilities and explore the underwater world at a new level?'
					}
				]
			}
		}
	}, {
		name: 'mouthfill',
		path: '/freediving/special-courses/mouthfill',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'mouthfill' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Master the Mouthfill Technique in Freediving with our 3-Day Course',
				titleRu: 'Овладейте техникой Mouthfill в фридайвинге с нашим 3-дневным курсом',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentRu: 'Углубите свои навыки фридайвинга с трехдневным курсом по технике наполнения рта всего за $250. Разработанный для опытных фридайверов, стремящихся покорить глубины более 40 метров, овладение техникой наполнения рта может помочь вам избежать распространенных ошибок и улучшить эффективность дыхания. Практические занятия в открытой воде помогут вам овладеть этой продвинутой техникой. Присоединяйтесь к нам, чтобы улучшить свои навыки и исследовать новые глубины вместе!',
						contentEn: 'Deepen your freediving skills with a 3-day Mouthfill technique course for just $250. Designed for experienced freedivers looking to conquer depths beyond 40 meters, mastering the Mouthfill technique can help you avoid common mistakes and improve breathing efficiency. Practical sessions in open water will help you master this advanced technique. Join us to enhance your skills and explore new depths together!'
					}
				]
			}
		}
	}, {
		name: 'kayak',
		path: '/freediving/special-courses/breath-holding-course-for-kayakers',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'kayak' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Freediving for Kayakers: Breath-Holding Course for Rapids Mastery',
				titleRu: 'Фридайвинг для каякеров: Курс задержки дыхания для владения порогами',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentRu: 'Присоединяйтесь к нашему интенсивному 2-3-дневному курсу, специально разработанному для каякеров, стремящихся улучшить свои навыки в управлении большими водными порогами. Стоимость курса - $250, включает все необходимое оборудование и учебные материалы для исследования глубин до 20 метров. Погрузитесь в мир фридайвинга, чтобы построить уверенность в преодолении водопадов и порогов, сосредотачиваясь на техниках задержки дыхания и психологических барьерах. Научитесь сдаваться воде, расслабляться и позволять ей направлять вас легко. Идеально подходит для тех, кто комфортно чувствует себя в воде и хочет покорить большие пороги, этот курс предлагает специализированные упражнения и психологические исследования для максимизации вашей производительности. Погрузитесь и готовьтесь к самому удивительному приключению на каяке!',
						contentEn: 'Join our intensive 2-3 day course designed specifically for kayakers seeking to enhance their skills for navigating big water rapids. Priced at $250, this course includes all equipment and training materials necessary to explore the depths up to 20 meters. Dive into the world of freediving to build confidence in handling waterfalls and rapids, focusing on breath-holding techniques and mental barriers. Learn to surrender to the water, relax, and allow it to guide you effortlessly. Perfect for those comfortable in water looking to conquer big rapids, this course offers specialized exercises and psychological insights to maximize your performance. Dive in and prepare for the ultimate kayaking adventure!'
					}
				]
			}
		}
	}, {
		name: 'unmonth',
		path: '/freediving/packages/unlimited-training-month',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'unmonth' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Dive Deeper: 30-Day Freediving Adventure in Puerto Galera',
				titleRu: 'Фридайвинг на глубину: 30-дневное приключение в Пуэрто-Галера',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Immerse yourself in a month-long freediving adventure in Puerto Galera with our comprehensive package. Explore the depths of the ocean, improve your skills with personal training, enjoy daily yoga sessions tailored for freedivers, and go on excursions to local attractions. Accommodation with essential amenities is provided, making your stay comfortable and convenient. Whether you\'re a beginner or aspiring instructor, this package offers an unforgettable experience in the stunning underwater world of Puerto Galera.',
						contentRu: 'Погрузитесь в месячное приключение по фридайвингу в Пуэрто-Галера с нашим комплексным пакетом. Исследуйте глубины океана, улучшайте свои навыки с персональным тренингом, наслаждайтесь ежедневными йога-сессиями, специально разработанными для фридайверов, и отправляйтесь на экскурсии к местным достопримечательностям. Предоставляется проживание с необходимыми удобствами, делая ваше пребывание комфортным и удобным. Независимо от того, являетесь ли вы начинающим или стремитесь стать инструктором, этот пакет предлагает незабываемый опыт в захватывающем подводном мире Пуэрто-Галера.'
					}
				]
			}
		}
	}, {
		name: 'nofins',
		path: '/freediving/special-courses/no-fins-practice',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'nofins' },
		meta: {
			seo: {
				titleEn: 'No Fins Mastery: Advanced Freediving Course for Fluid Movement',
				titleRu: 'Мастерство без ласт: Продвинутый курс фридайвинга для плавного движения',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentRu: 'Погрузитесь в искусство фридайвинга с нашим специализированным 2-дневным курсом, сосредоточенным на освоении техники без ласт. Стоимость курса - $200, он адаптирован под ваш уровень, позволяя вам исследовать глубины в зависимости от вашей профессиональной подготовки. Погрузитесь в мир фридайвинга, изучая правильные методы дыхания, методы релаксации и тренировки на выносливость для улучшения вашего выступления. Наши опытные инструкторы окажут персонализированное руководство, чтобы помочь вам совершенствовать свою технику и достигать оптимальных результатов.',
						contentEn: 'Immerse yourself in the art of freediving with our specialized 2-day course focused on mastering the no fins technique. Priced at $200, this course is tailored to your individual level, allowing you to explore depths based on your proficiency. Dive deep into the world of freediving as you learn proper breathing techniques, relaxation methods, and endurance training to enhance your performance. Our experienced instructors will provide personalized guidance to help you refine your technique and achieve optimal results.'
					}
				]
			}
		}
	}, {
		name: 'serfers',
		path: '/freediving/special-courses/breath-holding-course-for-surfers',
		component: () => import('../components/PpWave1Page.vue'),
		props: { course: 'serfers' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Surf Survival and Freediving Mastery for Surfers',
				titleRu: 'Выживание на серфе и мастерство фридайвинга для серферов',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Dive deep into the world of freediving with our specialized 2-day course designed for surfers. Priced at $250, this intensive program focuses on breath-holding techniques, overcoming mental barriers, and maximizing efficiency underwater. With a maximum depth of 20 meters, the course includes all necessary equipment and training materials. Perfect for surfers looking to enhance their skills for riding big waves, this course offers personalized instruction to help you reach your full potential in the water. Don\'t miss this opportunity to elevate your surfing game through the art of freediving!',
						contentRu: 'Погрузитесь в мир фридайвинга с нашим специализированным 2-дневным курсом, разработанным для серферов. Стоимость курса - $250, он направлен на освоение техник задержки дыхания, преодоление психологических барьеров и максимизацию эффективности под водой. Максимальная глубина погружения составляет 20 метров, включены все необходимые снаряжение и учебные материалы. Идеально подходит для серферов, желающих улучшить свои навыки для катания на больших волнах, этот курс предлагает персонализированное обучение, чтобы помочь вам раскрыть свой потенциал в воде. Не упустите возможность улучшить свое владение серфом через искусство фридайвинга!'
					}
				]
			}
		}
	}, {
		name: 'faq',
		path: '/faq',
		component: () => import('../components/PpFaqPage.vue'),
		meta: {
			seo: {
				title: '',
				titleEn: 'Frequently Asked Questions About Our Advanced Freediving Courses',
				titleRu: 'Часто задаваемые вопросы о наших продвинутых курсах фридайвинга'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: ' Explore our comprehensive FAQ section to find answers to common queries about our advanced freediving courses. From prerequisites for Level 2 and Level 3 courses to details on group sizes, equipment requirements, and safety considerations, we cover a wide range of topics to help you prepare for an enriching freediving experience. Discover insights on course scheduling, certification recognition, health benefits, and more. If you have any feedback or spot any errors on our website, please feel free to reach out – we value your input!',
					contentRu: 'Исследуйте наш развернутый раздел FAQ, чтобы найти ответы на распространенные вопросы о наших продвинутых курсах фридайвинга. От требований к уровню 2 и уровню 3 до деталей о размере групп, требованиях к снаряжению и мерам безопасности, мы охватываем широкий спектр тем, чтобы помочь вам подготовиться к насыщенному опыту фридайвинга. Откройте для себя информацию о расписании курсов, признании сертификатов, пользе для здоровья и многом другом. Если у вас есть отзывы или вы заметили ошибки на нашем сайте, не стесняйтесь обращаться - мы ценим ваше мнение!'
				}
			]
		}
	}, {
		name: 'base-courses',
		path: '/freediving/base-courses',
		component: () => import('../components/PpSpecCoursePage.vue'),
		props: { search: 'base-course' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Explore Our Advanced Freediving Courses for Mastering the Depths',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Dive deeper into the world of freediving with our advanced courses designed to enhance your skills and push your limits. From mastering breath-hold techniques to exploring depths of up to 40 meters (131 feet) in open water, our Wave 3 and Crossover courses offer a comprehensive training experience. Discover how to equalize below your residual volume, extend your breath-hold to over three and a half minutes, and leverage the benefits of the Molchanovs system. Whether you\'re a seasoned freediver or looking to transition to a new certification, our expert instructors are here to guide you on your journey to becoming a master freediver.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'contactwave1',
		path: '/contact/wave1',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'Wave 1' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'contactwave2',
		path: '/contact/wave2',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'Wave 2' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'contactwave3',
		path: '/contact/wave3',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'Wave 3' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'contactCO',
		path: '/contact/crossover',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'CrossOver' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'contact-line-practice',
		path: '/contact/line-practice',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'Line-practice' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'contact-training-session',
		path: '/contact/training-session',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'Master Course' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'contact-from-zero-to-hero',
		path: '/contact/from-zero-to-hero',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'from-zero-to-hero' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'contact-master-course',
		path: '/contact/master-course',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'Master Course' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'contact-fast-start',
		path: '/contact/fast-start',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'Fast Start' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'contact-all-puerto-galera',
		path: '/contact/all-puerto-galera',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'All Puerto Galera' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'contact-unlimited-training-month',
		path: '/contact/unlimited-training-month',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'Unlimited Training Month' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'wave-3-packetge',
		path: '/contact/wave-3-packetge',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'Wave 3 Packetge' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'mouthfill-course',
		path: '/contact/mouthfill-course',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'All Puerto Galera' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'breath-holding-course-for-kayakers',
		path: '/contact/breath-holding-course-for-kayakers',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'Breath holding course for kayakers' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'no-fins-practice',
		path: '/contact/no-fins-practice',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'No fins practice' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'contact-molchanovs-instructor-crossover',
		path: '/contact/Molchanovs-Instructor-Crossover',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'Molchanovs Instructor Crossover' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: 'Contact Us for Advanced Freediving Courses',
					titleEn: '',
					titleRu: ''
				}
			]
		}
	}, {
		name: 'contact-molchanovs-instructor-w2',
		path: '/contact/Molchanovs-Instructor-W2',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'Molchanovs-Instructor-W2' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: 'Contact Us for Advanced Freediving Courses',
					titleEn: '',
					titleRu: ''
				}
			]
		}
	}, {
		name: 'contact-molchanovs-instructor-w3',
		path: '/contact/Molchanovs-Instructor-W3',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'Molchanovs-Instructor-W3' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: 'Contact Us for Advanced Freediving Courses',
					titleEn: '',
					titleRu: ''
				}
			]
		}
	}, {
		name: 'surf-survival-and-freediving-for-surfers',
		path: '/contact/surf-survival-and-freediving-for-surfers',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'Surf Survival and Freediving for Surfers' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'blog',
		path: '/blog',
		component: () => import('../components/PpBlockPage.vue'),
		meta: {
			seo: {
				title: '',
				titleEn: 'Explore the World of Freediving Adventures',
				titleRu: 'Исследуйте мир приключений фридайвинга',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Dive into the exciting world of freediving with our blog! Learn about techniques, safety, equipment, and more to prepare for underwater adventures. Discover tips on choosing spearguns, improving breath-holding for kayaking, understanding freediving terminology, and mastering equalization techniques. Join us on this thrilling journey into the depths of the ocean!',
						contentRu: 'Окунитесь в захватывающий мир фридайвинга с нашим блогом! Узнайте о техниках, безопасности, оборудовании и многом другом, чтобы подготовиться к подводным приключениям. Откройте для себя советы по выбору гарпунов, улучшению задержки дыхания для каякинга, пониманию терминологии фридайвинга и овладению техниками уравнивания. Присоединяйтесь к нам в этом захватывающем путешествии в глубины океана!'
					}
				]
			}
		}
	}, {
		name: 'detaleblockgan',
		path: '/blog/how-to-choose-a-speargun',
		component: () => import('../components/PpDetaleBlockPage.vue'),
		props: { cWorld: 'detaleblockgan' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Choosing the Right Spearfishing Gun for Optimal Performance',
				titleRu: 'Выбор правильного подводного ружья для оптимальной производительности',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'When it comes to spearfishing, selecting the right gun is crucial for accuracy and success. Guns with rear grips are known for their accuracy, but may not be ideal for close-range shooting or limited spaces. For such conditions, central or front grip options are more optimal. The grip of the gun is essential for a comfortable and effective hunting experience.',
						contentRu: 'Когда дело доходит до гарпунной рыбалки, выбор правильного гарпуна критичен для точности и успеха. Гарпуны с задними рукоятками известны своей точностью, но могут быть не идеальными для стрельбы на близком расстоянии или в ограниченных пространствах. Для таких условий центральные или передние варианты рукояток более оптимальны. Рукоять гарпуна важна для комфортного и эффективного охотничьего опыта.'

					}
				]
			}
		}
	}, {
		name: 'kayakers',
		path: '/blog/practical-tips-on-breath-holding-for-kayakers',
		component: () => import('../components/PpDetaleBlockPage.vue'),
		props: { cWorld: 'kayakers' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Enhancing Breath-Holding Skills for Kayakers: Practical Tips for Success',
				titleRu: 'Улучшение навыков задержки дыхания для каякеров: практические советы для успеха',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Mastering the art of breath-holding is essential for kayakers facing challenging rapids and situations where quick thinking and calmness are key. This guide offers practical tips, from warming up with muscle stretches to taking full breaths before hitting a rapid. Learn how to avoid common mistakes like exhaling into the water and focus on relaxation techniques to conserve oxygen and stay composed underwater. By combining mental preparation with physiological training, kayakers can improve their breath-holding abilities and navigate through challenging scenarios with confidence.',
						contentRu: 'Овладение искусством задержки дыхания необходимо для каякеров, сталкивающихся с сложными порогами и ситуациями, где быстрое мышление и спокойствие играют ключевую роль. Этот руководство предлагает практические советы, начиная с разминки мышц до полного вдоха перед преодолением порога. Узнайте, как избежать распространенных ошибок, таких как выдыхание в воду, и сосредоточьтесь на техниках расслабления, чтобы сохранить кислород и оставаться спокойными под водой. Сочетая психологическую подготовку с физиологическим тренингом, каякеры могут улучшить свои навыки задержки дыхания и успешно справляться с сложными сценариями.'
					}
				]
			}
		}
	}, {
		name: 'how-to-get-to-puerto-galera',
		path: '/how-to-get-to-puerto-galera',
		component: () => import('../components/PpHowToGetToPage.vue'),
		meta: {
			seo: {
				title: '',
				titleEn: 'Getting to Puerto Galera: Transportation Options from Manila Airport',
				titleRu: 'Как добраться до Пуэрто-Галера: варианты транспорта от аэропорта Манилы',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentRu: 'Узнайте о лучших способах путешествия от аэропорта Манилы до Пуэрто-Галера, тропического рая на Филиппинах. Выберите между комфортабельной поездкой на такси прямо к пристани в Батангасе или более доступным путешествием на автобусе с потрясающими видами по пути. Узнайте о ценах на билеты, вариантах паромов и местном транспорте по прибытии, чтобы обеспечить гладкую и приятную поездку в Пуэрто-Галера. Дайте нам знать, если вы заметите какие-либо опечатки или ошибки на нашем сайте, так как мы стремимся к предоставлению точной информации нашим гостям.',
						contentEn: 'Discover the best ways to travel from Manila Airport to Puerto Galera, a tropical paradise in the Philippines. Choose between a comfortable taxi ride directly to Batangas pier or a more affordable bus journey with stunning views along the way. Learn about ticket prices, ferry options, and local transportation upon arrival to ensure a smooth and enjoyable trip to Puerto Galera. Let us know if you spot any typos or errors on our website as we strive for excellence in providing accurate information to our guests.'
					}
				]
			}
		}
	}, {
		name: 'review-page',
		path: '/review-page',
		component: () => import('../components/PpReviewPage.vue'),
		meta: {
			seo: {
				title: '',
				titleEn: 'Freedive-Inn: A Haven for Freediving Enthusiasts and Beach Lovers',
				titleRu: 'Freedive-Inn: Убежище для ценителей фридайвинга и любителей пляжей',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: ' Dive into the world of freediving at Freedive-Inn, a picturesque retreat in the Philippines that offers a perfect blend of relaxation and adventure. Discover the warm hospitality of the owners and the cozy ambiance that makes guests feel right at home. Explore the pristine beachfront property and experience the joy of learning freediving with professional instructors. From glowing reviews praising the welcoming atmosphere to the serene beauty of the surroundings, Freedive-Inn promises a memorable and rejuvenating escape for all who seek a peaceful getaway by the sea.',
						contentRu: 'Окунитесь в мир фридайвинга в Freedive-Inn, живописном убежище на Филиппинах, которое предлагает идеальное сочетание релаксации и приключений. Познакомьтесь с теплым гостеприимством хозяев и уютной атмосферой, которая заставляет гостей чувствовать себя как дома. Исследуйте непортребленное пляжное имущество и почувствуйте радость обучения фридайвингу с профессиональными инструкторами. От блестящих отзывов, восхваляющих дружелюбную атмосферу, до умиротворяющей красоты окружающей среды, Freedive-Inn обещает запоминающийся и оздоравливающий отдых для всех, кто ищет мирный убегающий у моря.'
					}
				]
			}
		}
	}, {
		name: 'dictionary',
		path: '/blog/freediving-dictionary',
		component: () => import('../components/PpDetaleBlockPage.vue'),
		props: { cWorld: 'dictionary', bold: true },
		meta: {
			seo: {
				title: '',
				titleEn: 'Freediving Essentials: A Comprehensive Dictionary of Terms and Techniques',
				titleRu: 'Основы фридайвинга: Полный словарь терминов и техник',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Dive into the world of freediving with this comprehensive dictionary that covers essential terms, concepts, and safety measures every freediver should know. From understanding the basics of apnea and breathing techniques to mastering disciplines like static apnea and constant weight diving, this guide provides valuable insights for both beginners and experienced freedivers. Learn about equalization methods, safety protocols to prevent hyperventilation, blackouts, and other risks, as well as how to recognize and address conditions like nitrogen narcosis and decompression illness. Whether you\'re exploring underwater depths or honing your skills in the pool, this resource is your go-to companion for a safe and fulfilling freediving experience.',
						contentRu: 'Окунитесь в мир фридайвинга с этим полным словарем, который охватывает основные термины, концепции и меры безопасности, которые должен знать каждый фридайвер. От понимания основ апноэ и техник дыхания до овладения дисциплинами, такими как статическая апноэ и погружение с постоянным весом, этот гид предоставляет ценные знания как для начинающих, так и для опытных фридайверов. Узнайте о методах эквализации, протоколах безопасности для предотвращения гипервентиляции, обмороков и других рисков, а также о том, как распознать и реагировать на состояния, такие как азотная наркоза и декомпрессионная болезнь. Независимо от того, исследуете ли вы подводные глубины или совершенствуете свои навыки в бассейне, этот ресурс станет вашим надежным спутником для безопасного и удовлетворительного опыта фридайвинга.'
					}
				]
			}
		}
	}, {
		name: 'equalization',
		path: '/blog/equalization-in-freediving',
		component: () => import('../components/PpDetaleBlockPage.vue'),
		props: { cWorld: 'equalization' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Mastering Equalization in Freediving: Overcoming Challenges and Enhancing Performance',
				titleRu: 'Улучшение навыков продувки в фридайвинге: Преодоление вызовов и улучшение результатов',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Dive deep into the world of freediving as we explore the essential skill of equalization and how it impacts your underwater experience. From understanding the common problems beginners face to advanced techniques for improving equalization, this article provides valuable insights for all levels of freedivers. Discover the importance of relaxation, body position, and stress management in achieving successful equalization, and learn how to address irregular equalization and fast descent issues. Find out how to prevent ear injuries, discomfort, and pain through gentle and regular equalization practices. Whether you\'re a novice or seasoned freediver, this guide will help you enhance your skills and enjoy a safer and more fulfilling diving experience.',
						contentRu: 'Погрузитесь в мир фридайвинга, исследуя важное умение эквализации и его влияние на ваше подводное погружение. От понимания распространенных проблем, с которыми сталкиваются новички, до продвинутых техник для улучшения эквализации, этот материал предоставляет ценные идеи для фридайверов всех уровней. Узнайте о важности релаксации, положения тела и управления стрессом для успешной эквализации, и научитесь решать проблемы с неравномерной эквализацией и быстрым спуском. Выясните, как предотвратить травмы ушей, дискомфорт и боль через мягкие и регулярные практики эквализации. Независимо от того, являетесь ли вы новичком или опытным фридайвером, этот руководство поможет вам улучшить навыки и насладиться более безопасным и удовлетворительным погружением.'
					}
				]
			}
		}
	}, {
		name: 'about-us',
		path: '/about-us',
		alias: '/about',
		component: () => import('../components/PpAboutUsPage.vue'),
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Познакомьтесь с нашей командой опытных инструкторов по фридайвингу в FreeDive Inn/School',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentRu: 'Узнайте больше о нашей команде опытных и страстных инструкторов в FreeDive Inn/School, включая Александра, Милу, Викторию и Еву. Познакомьтесь с их профессиональным опытом, сертификатами и уникальными переживаниями во фридайвинге, каякинге, фитнес-тренировках и многом другом. Узнайте, как каждый инструктор приносит свой опыт и личный путь, чтобы помочь студентам достичь своих целей во фридайвинге. Если вы заметите какие-либо ошибки или опечатки на нашем сайте, пожалуйста, не стесняйтесь связаться с нами, чтобы мы могли обеспечить точность для наших посетителей.',
						contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.'
					}
				]
			}
		}
	}, {
		name: 'contact-us',
		path: '/contact-us',
		alias: '/contact',
		component: () => import('../components/PpContactConPage.vue'),
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'contacthome',
		path: '/contact/contacthome',
		component: () => import('../components/PpContactPage.vue'),
		props: { course: 'ContacHome' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Contact Us for Advanced Freediving Courses',
				titleRu: 'Исследуйте наши продвинутые курсы фридайвинга для овладения глубинами'
			},
			metaTags: [
				{
					name: 'description',
					content: '',
					contentEn: 'Interested in our advanced freediving courses? Please provide your name, phone number, and message, and we will be happy to help you deepen your skills and reach new heights in the world of freediving. Our experienced instructors are ready to share their knowledge and assist you in becoming a true master of freediving.',
					contentRu: 'Погрузитесь глубже в мир фридайвинга с нашими продвинутыми курсами, разработанными для улучшения ваших навыков и преодоления ваших пределов. От овладения техникой задержки дыхания до исследования глубин до 40 метров в открытой воде, наши курсы Wave 3 и Crossover предлагают всесторонний опыт обучения. Узнайте, как производить уравнивание ниже остаточного объема, увеличивать задержку дыхания до более чем трех с половиной минут и использовать преимущества системы Molchanovs. Независимо от того, являетесь ли вы опытным фридайвером или хотите перейти на новый сертификат, наши опытные инструкторы готовы провести вас по пути к становлению мастером фридайвинга.'
				}
			]
		}
	}, {
		name: 'landtour',
		path: '/land-tour',
		component: () => import('../components/PpLandTour.vue'),
		meta: {
			seo: {
				title: '',
				titleEn: 'Discover the Beauty of Mindoro: Land Tours to Waterfalls and Beaches',
				titleRu: 'Откройте красоту Миндоро: экскурсии по суше к водопадам и пляжам',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Explore the stunning waterfalls and pristine beaches of Mindoro through our land tours, showcasing the majestic Tukuran, Tamaraw, and Aninuan waterfalls. Immerse yourself in the lush tropical surroundings as you embark on walking tours to these natural wonders, or opt for a big land tour to Ponderosa Mountain for panoramic views. Experience the tranquility of Talipanan Falls and visit an Indigenous Village for a cultural encounter. Mindoro\'s natural beauty awaits nature and adventure lovers seeking a paradise of relaxation and exploration. If you spot any errors or typos on our website, please inform us so we can ensure accuracy for all visitors.',
						contentRu: 'Исследуйте потрясающие водопады и бесподобные пляжи Миндоро через наши экскурсии по суше, демонстрирующие великолепные водопады Тукуран, Тамарау и Анинуан. Погрузитесь в зеленые тропические окрестности, отправляясь на пешие прогулки к этим природным чудесам, или выберитесь на большую экскурсию к горе Пондероса для панорамных видов. Почувствуйте спокойствие водопада Талипанан и посетите деревню коренных жителей для культурного знакомства. Природная красота Миндоро ждет любителей приключений и природы, ищущих рай для отдыха и исследований. Если вы заметите какие-либо ошибки или опечатки на нашем сайте, пожалуйста, сообщите нам, чтобы мы могли обеспечить точность для всех посетителей.'
					}
				]
			}
		}
	}, {
		name: 'tucuran',
		path: '/land-tour/ponderosa-mountain-and-tucuran-waterfalls',
		component: () => import('../components/PpLandTourPage.vue'),
		props: { tour: 'tucuran' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Explore the Beauty of Mindoro: Ponderosa Mountain and Tucuran Waterfalls Adventure',
				titleRu: 'Исследуйте красоту Миндоро: гора Пондероса и приключения у водопадов Тукуран',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Discover the awe-inspiring Ponderosa Mountain with its panoramic views and thrilling activities like ziplining and bungee jumping. Immerse yourself in the natural beauty of Tucuran Falls, where you can ride carabao carts through the jungle to reach the cascading waterfalls for a refreshing swim and snorkeling experience. Experience the best of Mindoro\'s landscapes and adventures on this unforgettable journey. If you notice any errors or typos on our website, please inform us so we can ensure accuracy for all visitors.',
						contentRu: 'Откройте великолепную гору Пондероса с ее панорамными видами и захватывающими активностями, такими как тиролинг и прыжки с батута. Погрузитесь в природную красоту водопадов Тукуран, где вы сможете проехать на телегах карабао через джунгли, чтобы добраться до струящихся водопадов для освежающего купания и сноркелинга. Почувствуйте лучшее из пейзажей и приключений Миндоро на этом незабываемом путешествии. Если вы заметите какие-либо ошибки или опечатки на нашем сайте, пожалуйста, сообщите нам, чтобы мы могли обеспечить точность для всех посетителей.'
					}
				]
			}
		}
	}, {
		name: 'aninuan',
		path: '/land-tour/aninuan-waterfalls',
		component: () => import('../components/PpLandTourPage.vue'),
		props: { tour: 'aninuan' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Discover the Beauty of Aninuan Waterfalls in Mindoro',
				titleRu: 'Откройте красоту водопадов Анинуан на Миндоро',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentRu: 'Отправляйтесь в живописное путешествие к водопадам Анинуан, всего в часе езды от нашего отеля. Выберите между живописным маршрутом по старой дороге или более коротким путем вдоль русла ручья, чтобы добраться до этого захватывающего природного чуда. Плавайте, прыгайте и делайте потрясающие фотографии на фоне струящегося водопада. Для любителей походов отправьтесь выше по дороге к ветрякам и насладитесь панорамными видами на ландшафт. Почувствуйте красоту водопадов Анинуан и создайте незабываемые воспоминания на Миндоро. Мы очень ценим ваш отзыв о любых опечатках или ошибках на нашем сайте.',
						contentEn: 'Embark on a picturesque journey to Aninuan Waterfalls, just an hour away from our hotel. Choose between a scenic route along the old road or a shorter path along the stream bed to reach this breathtaking natural wonder. Swim, jump, and capture stunning photos against the backdrop of the cascading waterfall. For hiking enthusiasts, venture higher up the road to reach the windmills and revel in panoramic views of the landscape. Experience the beauty of Aninuan Waterfalls and create unforgettable memories in Mindoro. Your feedback on any typos or errors on our website is greatly appreciated.'
					}
				]
			}
		}
	}, {
		name: 'tamaraw',
		path: '/land-tour/ponderosa-mountain-and-tamaraw-waterfalls',
		component: () => import('../components/PpLandTourPage.vue'),
		props: { tour: 'tamaraw' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Explore the Beauty of Ponderosa Mountain and Tamaraw Waterfall in Mindoro',
				titleRu: 'Исследуйте красоту горы Пондероса и водопада Тамарав на Миндоро',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: 'Discover the majestic Ponderosa Mountain, offering stunning views of the mountains, sea, and Puerto Galera Bay. Visit the lookout point and golf course for a memorable experience. Just 25 minutes away from our hotel, Tamaraw Waterfalls is a family-friendly destination with water slides, pools, gazebos for grilling and karaoke, and cozy bungalows for overnight stays. Children will love this aqua park-like setting with crystal-clear mountain water. Immerse yourself in the natural beauty of Mindoro and create unforgettable memories at Ponderosa Mountain and Tamaraw Waterfall. Your feedback on any typos or errors on our website is greatly appreciated.',
						contentRu: 'Откройте величественную гору Пондероса, предлагающую потрясающие виды на горы, море и залив Пуэрто-Галера. Посетите смотровую площадку и поле для гольфа для незабываемого опыта. Всего в 25 минутах езды от нашего отеля находится водопад Тамарав, популярное место для отдыха всей семьей с водными горками, бассейнами, беседками для шашлыка и караоке, а также уютными бунгало для ночевки. Дети полюбят этот аквапарк с кристально чистой горной водой. Погрузитесь в природную красоту Миндоро и создайте незабываемые воспоминания на горе Пондероса и водопаде Тамарав. Мы очень ценим ваш отзыв о любых опечатках или ошибках на нашем сайте.'
					}
				]
			}
		}
	}, {
		name: 'indigenousvillage',
		path: '/land-tour/talipanan-falls-and-indigenous-village',
		component: () => import('../components/PpLandTourPage.vue'),
		props: { tour: 'indigenousvillage' },
		meta: {
			seo: {
				title: '',
				titleEn: 'Explore the Serenity of Talipanan Falls and Native Village in Mindoro',
				titleRu: 'Исследуйте Умиротворение Талипананских Водопадов и Коренной Деревни на Миндоро',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: ' Embark on a half-hour walk through a scenic jungle trail to discover the hidden gem of Talipanan Falls and Lagoon, nestled in the mountains near the dive center. As you trek through the lush surroundings, you will also pass through the native village of the Mangyan people, the indigenous population of Mindoro. Immerse yourself in their semi-wild and semi-nomadic way of life, witness their traditional activities such as gathering, hunting, and fishing, and browse through unique souvenirs and woven utensils available for purchase in the village. Experience the tranquility of nature and the rich culture of Mindoro at Talipanan Falls and Native Village. Your feedback on any website errors or typos is highly valued as we strive to improve our platform.',
						contentRu: 'Отправьтесь в полуторачасовую прогулку по живописной тропе джунглей, чтобы открыть для себя скрытый драгоценный камень Талипананских водопадов и лагуны, утопленных в горах недалеко от центра дайвинга. Пройдя через пышную природу, вы также пройдете через коренную деревню местного народа Мангьян, коренного населения Миндоро. Погрузитесь в их полу-дикий и полу-кочевой образ жизни, станьте свидетелем их традиционных занятий, таких как сбор, охота и рыбалка, и посмотрите уникальные сувениры и плетеные посуды, доступные для покупки в деревне. Почувствуйте спокойствие природы и богатую культуру Миндоро у Талипананских водопадов и коренной деревни. Ваш отзыв о любых ошибках или опечатках на сайте имеет большую ценность для нас, так как мы стремимся улучшить нашу платформу.'
					}
				]
			}
		}
	}, {
		name: 'pp404',
		path: '/error/404',
		component: () => import('../components/Pp404Page.vue'),
		meta: {
			seo: {
				title: '',
				titleEn: '',
				titleRu: '',
				metaTags: [
					{
						name: 'description',
						content: '',
						contentEn: '',
						contentRu: ''
					}
				]
			}
		}
	}, {
		path: '/:pathMatch(.*)*',
		name: 'not-found',
		redirect: '/error/404'
	}
];

const router = createRouter({
	routes: routes,
	mode: 'history',
	base: __dirname,
	history: createWebHistory(),
	async scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		}
		if (to.hash) {
			return {
				el: to.hash,
				behavior: 'smooth'
			};
		}
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 500);
		return { top: 0 };
	}
});

router.beforeEach((to, from, next) => {
	if (store.getters['main/getLanguage'] === 'ru') {
		to.meta.seo.title = to.meta.seo.titleRu;
		if (!to.path.includes('contact') && !to.path.includes('base-courses')) {
			to.meta.seo.metaTags[0].content = to.meta.seo.metaTags[0].contentRu;
		}
	} else {
		to.meta.seo.title = to.meta.seo.titleEn;
		if (!to.path.includes('contact') && !to.path.includes('base-courses')) {
			to.meta.seo.metaTags[0].content = to.meta.seo.metaTags[0].contentEn;
		}
	}
	next();
});

router.beforeEach(seoGuardWithNext);

export default router;
